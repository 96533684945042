<template>
    <main class="drainage main">
        <h1>Дренажные системы</h1>
        <div class="imgs">
            <img 
              v-for="(src, index) in images" 
              :src="src" 
              :key="index" 
              alt="Дренажная система" 
              class="img" 
              @click="openModal(index)"
            />
        </div>

        <div v-if="isModalOpen" class="modal" @click="closeModal">
            <div class="modal-content" @click.stop>
                <button v-if="currentIndex > 0" class="prev" @click="prevImage">‹</button>
                <img 
                    :src="images[currentIndex]" 
                    alt="Enlarged image" 
                    class="zoomable-img" 
                    ref="zoomImg" 
                    @wheel="zoomImage"
                />
                <button v-if="currentIndex < images.length - 1" class="next" @click="nextImage">›</button>
            </div>
        </div>

        <div class="content">
            <div class="contents content1">
                <h2>    
                    Дренаж участка – необходимый комплекс работ
                </h2>
                <ul>
                    Проблему болотистых почв и повышенной влажности решает профессиональная дренажная система. Компания «Нева-Плитка» предлагает комплексный монтаж системы отвода ливневых и грунтовых вод:
                    <li>оценка характеристики грунтов на участке – плотность,
                     состав, рельеф местности, направление сезонных ливневых вод,
                     определение уклона участка, необходимость дополнительной планировки территории;</li>
                    <li>на местности мастер компании производит необходимые геодезические замеры
                     перепадов высот для составления технического плана-проекта дренажной системы;</li>
                    <li>иногда достаточно установить ливневый дренаж, 
                    который отводит ливневые воды от фундамента дома, дорожек, площадок и беседок;</li>
                    <li>
                        при высоком залегании грунтовых вод, угрожающим целостности фундамента,
                        нужен монтаж подземной дренажной системы;
                    </li>
                    <li>
                        при составлении проекта инженер компании учтет все индивидуальные характеристики участка и поможет
                        подобрать оптимальный по цене и характеристикам вариант дренажной системы;
                    </li>
                    <li>
                        работы по монтажу ливнестоков выполняет бригада опытных мастеров компании,
                        строго в соответствии с инженерным планом проектом.
                    </li>
                    <br/>
                    <p>Так как наша компании производит тротуарную плитку и предоставляет комплекс работ по благоустройству 
                    территорий загородных домов (мощение дорожек, площадок, подъездных путей), то нам знакомы проблемы,
                    которые могут вызвать неконтролируемые ливневые воды. Инженеры «Нева-Плитка» знают, как правильно установить дренажную систему на загородном участке.
                </p>
                    
                </ul>
            </div>
            <div class="contents content2">
                    <h2>
                        Монтаж дренажной системы «под ключ»
                    </h2>
                    <ul>Правильный, профессиональный дренаж на 
                    загородном участке – решение проблемы повышенной влажности и грунтовых вод навсегда:
                    <li>
                        культивирование почвы на участке за счет снижения высокой влажности гарантирует высокие урожаи и отсутствие болезней плодовых и ягодных культур;
                    </li>
                    <li>
                        защита фундамента от разрушительного действия грунтовых вод, комфортный микроклимат в доме;
                    </li>
                    <li>
                        предотвращение размывания дорожек и благоустроенных площадок, клумб и цветников;
                    </li>
                    <li>
                        устройство емкостей для сбора ливневых вод и существенная экономия на поливе;
                    </li>
                    <li>
                        устройство ливнестоков и систем отвода с учетом эстетики дизайна ландшафта двора и сада.
                    </li>
                </ul>
                    <p>Стоимость работ по устройству дренажа участка зависит от многих индивидуальных параметров территории, которые всегда можно обсудить с инженером компании «Нева-Плитка», чтобы подобрать оптимальный по цене и эффективности вариант.</p>
                    <h2>
                        Стоимость работ
                    </h2>
                    <p>
                    Вопрос стоимости дренажной системы можно решить только исходя из индивидуальных параметров территории. 
                    Звоните, или закажите обратный звонок на сайте – оговорим все вопросы и выберем удобное время, когда мастер сможет приехать на участок.
                    </p>
                    <br/>
                    <p>
                    После изучения параметров территории и предварительного осмотра специалисты компании смогут подобрать наилучший вариант дренажной системы для Вашего участка,
                    учитывая финансовые возможности и все необходимые для расчетов характеристики отдельного участка.
                    </p>
            </div>
        </div>
       
    </main>
</template>

<script>
export default {
    data() {
        return {
            isModalOpen: false,
            currentIndex: 0,
            zoomLevel: 1,
            images: [
                require('../../public/images/sistemy/dren1.jpg'),
                require('../../public/images/sistemy/dren2.jpg'),
                require('../../public/images/sistemy/dren3.jpg'),
                require('../../public/images/sistemy/dren4.jpg'),
                require('../../public/images/sistemy/dren5.jpg')
            ]
        };
    },
    methods: {
        openModal(index) {
            this.currentIndex = index;
            this.isModalOpen = true;
            this.zoomLevel = 1;
        },
        closeModal() {
            this.isModalOpen = false;
        },
        zoomImage(event) {
            event.preventDefault();
            const zoomStep = 0.1;
            if (event.deltaY > 0) {
                this.zoomLevel = Math.max(this.zoomLevel - zoomStep, 1); 
            } else {
                this.zoomLevel += zoomStep; 
            }
            this.$refs.zoomImg.style.transform = `scale(${this.zoomLevel})`;
        },
        nextImage() {
            if (this.currentIndex < this.images.length - 1) {
                this.currentIndex++;
                this.zoomLevel = 1; 
            }
        },
        prevImage() {
            if (this.currentIndex > 0) {
                this.currentIndex--;
                this.zoomLevel = 1; 
            }
        }
    }
};
</script>

<style scoped>
.drainage {
    margin: 10px;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.drainage h1 {
    padding-bottom: 25px;
    color: #c7c6c6;
}

.content {
    width: 100%;
    display: flex;
    justify-content: space-around;
    gap: 25px;
    border: 2px solid #c7c6c6;
    padding: 10px;
    border-radius: 4px;
    background-color: #fff3f3;

}

.contents {
    width: 45%;
    display: flex;
    flex-direction: column;
    gap: 5px;
}

.imgs {
    margin-top: 10px;
    display: flex;
    justify-content: center; 
    flex-wrap: wrap; 
    gap: 15px; 
}

.img {
    width: 300px;
    height: 200px;
    transition: box-shadow 0.5s ease;
    cursor: pointer;
    object-fit: cover;
    border-radius: 8px; 
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
}

.img:hover {
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.3);
}

.modal {
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background: rgba(0, 0, 0, 0.8);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
}

.modal-content {
    max-width: 90%;
    max-height: 90%;
    display: flex;
    align-items: center;
    position: relative;
    overflow: hidden;
}

.zoomable-img {
    max-width: 100%;
    max-height: 100%;
    transition: transform 0.3s ease;
    cursor: zoom-in;
}

.prev, .next {
    position: absolute;
    top: 50%;
    background: rgba(0, 0, 0, 0.5);
    color: white;
    border: none;
    padding: 10px;
    cursor: pointer;
    font-size: 30px;
    transform: translateY(-50%);
}

.prev {
    left: 10px;
}

.next {
    right: 10px;
}

.prev:hover, .next:hover {
    background: rgba(0, 0, 0, 0.8);
}
@media (max-width: 1500px) {
    .img {
        width: calc(20% - 15px); 

    }
}

@media (max-width: 1200px) {
    .img {
        width: calc(25% - 15px);
    }
    
    .content {
        flex-direction: column; 
        align-items: center; 
    }
    
    .contents {
        width: 100%; 
    }
}

@media (max-width: 900px) {
    .img {
        width: calc(33.33% - 15px); 
    }
}

@media (max-width: 600px) {
    .img {
        width: calc(50% - 15px); 
    }
}

@media (max-width: 400px) {
    .img {
        width: 100%; 
    }
}

@media (max-width: 800px) {
    .content {
        gap: 15px; 
    }
    
    .contents {
        width: 90%; 
    }
}

@media (max-width: 500px) {
    .contents {
        width: 100%; 
        overflow: hidden;
    }

    .drainage h1 {
        font-size: 1.5em; 
    }
    
    .contents h2 {
        font-size: 1.2em; 
    }

    .contents p {
        font-size: 0.9em; 
    }
}

</style>